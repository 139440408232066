import {Injectable} from '@angular/core';
import Pusher, {Channel} from "pusher-js";
import {NgEventBus} from "ng-event-bus";
import {PUSHER_CONNECTED, PUSHER_LOGIN_EVENT} from "../common/app-events";
import {AuthService} from "../common/services/auth.service";
import {ApiService} from "../common/services/api.service";

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  public connectedChannels: string[] = [];
  public pusher!: Pusher;
  public currentState = 'Disconnected';

  constructor(public eventBus: NgEventBus, public authService: AuthService, public apiService: ApiService) {
    Pusher.logToConsole = true;
    this.pusher = new Pusher('0abf50dc3568dc82454e', {
      cluster: 'ap2',
      channelAuthorization: {
        endpoint: "/api/v1/broadcasting/auth",
        transport: 'ajax',
        customHandler: (channel, options) => {
          this.apiService.post('broadcasting/auth', {
            socket_id: channel.socketId,
            channel_name: channel.channelName
          })
            .subscribe((response: any) => {
              console.log(response)
              //callback(false, response.data);
            })

        }
      },
      userAuthentication: {
        endpoint: "/api/v1/broadcasting/auth",
        transport: 'ajax',
        customHandler: (channel, options) => {
          this.apiService.post('broadcasting/auth', {
            socket_id: channel.socketId,
          })
            .subscribe((response: any) => {
              console.log(response)
              //callback(false, response.data);
            })

        }
      },
    });


    this.registerPusherEvents();
  }

  registerPusherEvents() {
    this.pusher.connection.bind('state_change', (states: any) => {
      switch (states.current) {
        case 'connected':
          this.currentState = 'Connected';
          this.eventBus.cast(PUSHER_CONNECTED);
          break;
        case 'disconnected':
          this.currentState = 'Disconnected';
          this.pusher.connect();
          break;
      }
    });


  }


  connectToChannel(channelName: string): Channel {
    this.connectedChannels.push(channelName);
    return this.pusher.subscribe(channelName);
  }

}
