import {Component} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {ImageUploadComponent} from "../common/components/image-upload.component";
import {JsonPipe, NgClass, NgIf} from "@angular/common";
import {ApiService} from "../common/services/api.service";
import {CalendarModule} from "primeng/calendar";
import {errorTailorImports} from "@ngneat/error-tailor";
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {ModelLoaderService} from '../services/model-loader.service';
import {DropdownModule} from "primeng/dropdown";
import {StyleClassModule} from "primeng/styleclass";

@Component({
  selector: 'app-update-profile',
  standalone: true,
  template: `
    <form [formGroup]="form" (ngSubmit)="save()" errorTailor
          [ngClass]="{'bg-white p-8 mx-auto w-[600px] my-10':register}">
      <div class="form-group">
        <app-image-upload [url]="'upload'" formControlName="photo"></app-image-upload>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <div class="form-group">
          <label for="" class="form-label">Name</label>
          <input type="text" formControlName="name" class="form-control">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Email</label>
          <input type="text" formControlName="email" class="form-control">
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <div class="form-group">
          <label for="" class="form-label">PAN</label>
          <input type="text" formControlName="pan" class="form-control">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Addhar Card</label>
          <input type="text" formControlName="adhar" class="form-control">
        </div>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Phone</label>
        <input type="text" formControlName="phone" class="form-control">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Studio</label>
        <p-dropdown [options]="studios" [autoDisplayFirst]="false" styleClass="w-full"
                    formControlName="studio_id"></p-dropdown>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <div class="form-group">
          <label for="" class="form-label">Joining Date</label>
          <p-calendar styleClass="w-full" formControlName="joining_date" class="form-control"></p-calendar>
        </div>
        <div class="form-group">
          <label for="" class="form-label">Date Of Birth</label>
          <p-calendar styleClass="w-full" formControlName="date_of_birth" class="form-control"></p-calendar>
        </div>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Address</label>
        <input styleClass="w-full" formControlName="address" class="form-control"/>
      </div>
      <ng-container *ngIf="!register">
        <h3 class="mb-1 mt-4">Update Password</h3>
        <small>Leave this field empty to keep you old password</small>
      </ng-container>
      <div class="form-group mt-6">
        <label for="" class="form-label">Password</label>
        <input type="password" formControlName="password" class="form-control">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Confirm Password</label>
        <input type="password" formControlName="confirm_password" class="form-control">
      </div>
      <button class="action-btn" type="submit">{{register ? 'Create Account' : 'Update Profile'}}</button>

      <div *ngIf="register">
        <a routerLink="/" class="block text-center no-underline mt-8" href="">Back to Login</a>
      </div>
    </form>
  `,
  imports: [
    ReactiveFormsModule,
    errorTailorImports,
    ImageUploadComponent,
    JsonPipe,
    CalendarModule,
    NgClass,
    NgIf,
    DropdownModule,
    StyleClassModule,
    RouterLink,
  ],
  styles: []
})


export class UpdateProfileComponent {
  public register = false;
  public studios = [];

  matchValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (abstractControl: AbstractControl) => {
      const control = abstractControl.get(controlName);
      const matchingControl = abstractControl.get(matchingControlName);

      if (matchingControl!.errors && !matchingControl!.errors?.['confirmedValidator']) {
        return null;
      }

      if (control!.value !== matchingControl!.value) {
        const error = {confirmedValidator: 'Passwords do not match.'};
        matchingControl!.setErrors(error);
        return error;
      } else {
        matchingControl!.setErrors(null);
        return null;
      }
    }
  }

  public form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    photo: new FormControl(''),
    phone: new FormControl('', [Validators.required]),
    pan: new FormControl(),
    adhar: new FormControl(),
    joining_date: new FormControl<any>(null),
    date_of_birth: new FormControl<any>(null),
    address: new FormControl(),
    password: new FormControl(),
    confirm_password: new FormControl(),
    studio_id: new FormControl(),
  });

  constructor(public api: ApiService,
              public router: Router,
              public activatedRoute: ActivatedRoute, public modelLoaderService: ModelLoaderService) {
    this.modelLoaderService.load('studios').subscribe(res => this.studios = res);
    this.activatedRoute.data.subscribe((res: { register: number }) => {
      if (res.register == 1) {
        this.register = true;
        this.form.controls['pan'].addValidators([Validators.required]);
        this.form.controls['adhar'].addValidators([Validators.required]);
        this.form.controls['studio_id'].addValidators([Validators.required]);
        this.form.controls['password'].addValidators([Validators.required]);
        this.form.controls['joining_date'].addValidators([Validators.required]);
        this.form.controls['date_of_birth'].addValidators([Validators.required]);
        this.form.controls['address'].addValidators([Validators.required]);
        this.form.controls['photo'].addValidators([Validators.required]);
        this.form.addValidators(this.matchValidator('password', 'confirm_password'));
        return;
      }
      this.api.get('user/profile', {}, false).subscribe((res: any) => {
        this.form.patchValue(res);
        var clonedItem: any = {'date_of_birth': null, 'joining_date': null}
        if (res.date_of_birth) clonedItem.date_of_birth = new Date(res.date_of_birth);
        if (res.joining_date) clonedItem.joining_date = new Date(res.joining_date);
        this.form.patchValue(clonedItem);

      })
    })
  }


  getFormValidationErrors() {
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  save() {
    if (this.form.invalid) {
      this.getFormValidationErrors()
      return;
    }
    this.api.post(this.register ? 'register' : 'user/update-profile', this.form.value)
      .subscribe((res: any) => {
        if (this.register) {
          this.form.reset();
          this.router.navigate(['/']).then();
        }
      })
  }
}
