import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {LocalStorage} from "ngx-webstorage";

@Injectable({
  providedIn: 'root',
})

export class AuthService extends ApiService {

  @LocalStorage()
  public user: any;

  login(credentials: ILoginCredentials) { return this.post('login', credentials); }

  logout() { return this.post('logout', null); }

  loggedIn() {
    return this.user !== null;
  }
}
