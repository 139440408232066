import { Injectable } from '@angular/core';
import { from } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class TopLoaderService {
  public visible = false

  constructor() { }

  show() {this.visible = true}

  hide() {this.visible = false}
}
